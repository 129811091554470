import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { class: "uk-h3 uk-text-bold uk-text-center mg-text" }
const _hoisted_5 = { key: 3 }
const _hoisted_6 = { class: "uk-margin-bottom" }
const _hoisted_7 = { class: "uk-text-muted uk-text-small" }
const _hoisted_8 = ["value", "max"]
const _hoisted_9 = { class: "uk-h3 uk-text-bold mg-text" }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = {
  key: 0,
  class: "question-block"
}
const _hoisted_12 = {
  key: 0,
  class: "uk-flex uk-flex-column uk-text-left question-block"
}
const _hoisted_13 = {
  key: 1,
  class: "uk-flex uk-flex-column uk-text-left question-block"
}
const _hoisted_14 = { key: 4 }
const _hoisted_15 = { class: "uk-margin-bottom" }
const _hoisted_16 = ["href"]
const _hoisted_17 = { key: 5 }
const _hoisted_18 = { key: 6 }
const _hoisted_19 = { class: "uk-flex uk-flex-between" }

import {computed, ref} from 'vue';
import {userDecksStore} from "@/store/deck";
import {useRouter} from "vue-router";
import {LoadingState} from "@/models/api/v1/LoadingState";
import Loading from "@/components/Loading.vue";
import CardRadio from "@/components/poll/CardRadio.vue";
import Checkbox from "@/components/poll/Checkbox.vue";
import Radio from "@/components/poll/Radio.vue";
import {Api} from "@/types/api/v1/poll";
import QuestionAnswer from "@/models/api/v1/QuestionAnswer";
import { useNotification } from "@kyvg/vue3-notification";

import Quiz = Api.V1.Quiz;
import Question = Api.V1.Question;
type DeckProps = {
  deckId: number
}


export default /*@__PURE__*/_defineComponent({
  __name: 'DeckView',
  props: {
    deckId: {}
  },
  setup(__props: any) {

const router = useRouter()
const store = userDecksStore();
const notifications = useNotification();

const props = __props;

const currentPoll = ref<Quiz>();
const deckIsEmpty = ref(false);
const pollAnswered = ref(false);
const answers = ref<QuestionAnswer[]>([]);

const answered = ref(0);
const currentIndex = ref(0);
const hasAnyPolls = computed(() => store.selectedDeck.polls.length > 1);
const percent = computed(() => ((answered.value / store.selectedDeckTotal) * 100).toFixed(0))

store.loadDeck(props.deckId).then(() => {
  setNextPoll();
})

const setNextPoll = () => {

  let total = store.selectedDeck.polls.length - 1;
  let nextIndex = currentIndex.value + 1;

  if(total < 0){
    deckIsEmpty.value = true;
  }

  if (nextIndex > total) {
    nextIndex = 0;
  }

  // Считаем сколько всего разрешенных опросов
  const length = store.selectedDeck.polls.length - 1;
  // Если не осталось - колода пуста
  if (length < 0) {
    deckIsEmpty.value = true;
  }

  window.document.title = store.selectedDeck.deck.deck_name_result;


  currentPoll.value = store.selectedDeck.polls[nextIndex];
  currentIndex.value = nextIndex;
}


const answer = () => {
  const questions = currentPoll.value?.questions.map((question: Question) => question.id);

  questions.map((id: number) => {
    store.removeFromDeck(id);
  });

  pollAnswered.value = true;
  answered.value++;

  if (answers.value.length > 0) {
    store.sendAnswer(currentPoll.value.id, answers.value).catch((data) =>{
      notifications.notify({
        title: 'Ошибка',
        type: 'error',
        text: 'Не удалось сохранить ответ, повторите попытку позже или обратитесь к руководителю'
      });

    })
  }

}

const next = () => {
  pollAnswered.value = false;
  answers.value = [];
  setNextPoll();

}

const setAnswer = (answer: QuestionAnswer) => {
  // Ищем индекс существующего answer с таким же question
  const index = answers.value.findIndex((a) => a.question === answer.question);
  const answered = index !== -1; // Проверяем, существует ли answer с таким question

  const hasSelectedVariants = answer.variants.length > 0; // Проверяем, есть ли выбранные варианты

  if (answered && !hasSelectedVariants) {
    // Если answer существует, но нет выбранных вариантов, удаляем его из массива
    answers.value.splice(index, 1);
  } else if (answered) {
    // Если answer с таким question уже существует, заменяем его
    answers.value[index] = answer;
  } else {
    // Если answer с таким question не существует, добавляем его в массив
    answers.value.push(answer);
  }
};

const toDecks = () => {
  router.push({
    name: 'home',
  })
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(store).selectedDeckLoading !== _unref(LoadingState).Loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("a", {
            class: "uk-link-muted",
            href: "#",
            onClick: toDecks
          }, " К списку колод ")
        ]))
      : _createCommentVNode("", true),
    (_unref(store).selectedDeckLoading == _unref(LoadingState).Loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(Loading)
        ]))
      : _createCommentVNode("", true),
    (_unref(store).selectedDeckLoading == _unref(LoadingState).Failed)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("h3", _hoisted_4, _toDisplayString(_unref(store).selectedDeckLoadingMessage), 1)
        ]))
      : _createCommentVNode("", true),
    (_unref(store).selectedDeckLoading == _unref(LoadingState).Success && currentPoll.value && !deckIsEmpty.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("p", _hoisted_7, [
              _cache[0] || (_cache[0] = _createTextVNode("Готово: ")),
              _createElementVNode("span", null, _toDisplayString(percent.value) + "%", 1)
            ]),
            _createElementVNode("progress", {
              class: "uk-progress uk-margin-remove-bottom",
              value: (answered.value),
              max: (_unref(store).selectedDeckTotal)
            }, null, 8, _hoisted_8)
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(currentPoll.value.questions, (question) => {
            return (_openBlock(), _createElementBlock("div", null, [
              _createElementVNode("h3", _hoisted_9, _toDisplayString(question.text), 1),
              _createElementVNode("div", {
                class: "uk-text-muted question-description",
                innerHTML: question.description
              }, null, 8, _hoisted_10),
              _createElementVNode("div", null, [
                (question.type === 'cards-radio')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      _createVNode(CardRadio, {
                        question: question,
                        disabled: pollAnswered.value,
                        onChange: setAnswer
                      }, null, 8, ["question", "disabled"])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              (question.type === 'radio')
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    _createVNode(Radio, {
                      question: question,
                      disabled: pollAnswered.value,
                      onChange: setAnswer
                    }, null, 8, ["question", "disabled"])
                  ]))
                : _createCommentVNode("", true),
              (question.type === 'checkbox')
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    _createVNode(Checkbox, {
                      question: question,
                      disabled: pollAnswered.value,
                      onChange: setAnswer
                    }, null, 8, ["question", "disabled"])
                  ]))
                : _createCommentVNode("", true)
            ]))
          }), 256))
        ]))
      : _createCommentVNode("", true),
    (pollAnswered.value && !deckIsEmpty.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _cache[2] || (_cache[2] = _createElementVNode("h3", null, "Бесконечные знания", -1)),
            _createElementVNode("p", null, [
              _cache[1] || (_cache[1] = _createTextVNode(" Ответ на вопрос можно найти в документации. Изучить материал можно ")),
              _createElementVNode("a", {
                class: "uk-link-text uk-text-danger",
                href: currentPoll.value.article.url,
                target: "_blank"
              }, "по ссылке ", 8, _hoisted_16)
            ])
          ]),
          _createElementVNode("div", { class: "uk-flex uk-flex-left" }, [
            _createElementVNode("button", {
              class: "uk-button-danger uk-button",
              onClick: next
            }, " Далее ")
          ])
        ]))
      : _createCommentVNode("", true),
    (deckIsEmpty.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
          _createElementVNode("div", { class: "uk-margin-bottom" }, [
            _createElementVNode("div", { class: "uk-text-center" }, [
              _cache[3] || (_cache[3] = _createElementVNode("p", { class: "uk-h1 mg-text" }, "Колода пуста", -1)),
              _cache[4] || (_cache[4] = _createElementVNode("p", null, "Вы отметили на все вопросы в колоде", -1)),
              _createElementVNode("button", {
                class: "uk-button-danger uk-button",
                onClick: toDecks
              }, " Выбрать другую колоду ")
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (!pollAnswered.value && currentPoll.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("div", null, [
              _createElementVNode("button", {
                class: "uk-button-danger uk-button uk-margin-right",
                onClick: answer
              }, " Ответить ")
            ]),
            _createElementVNode("div", null, [
              (hasAnyPolls.value)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    class: "uk-button-default uk-button",
                    onClick: next
                  }, " Пропустить "))
                : _createCommentVNode("", true)
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})