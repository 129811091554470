<template>
  <div v-if="store.selectedDeckLoading !== LoadingState.Loading">
    <a class="uk-link-muted" href="#" @click="toDecks">
      К списку колод
    </a>
  </div>
  <div v-if="store.selectedDeckLoading == LoadingState.Loading">
    <Loading/>
  </div>
  <div v-if="store.selectedDeckLoading == LoadingState.Failed">
    <h3 class="uk-h3 uk-text-bold uk-text-center mg-text"> {{ store.selectedDeckLoadingMessage }}</h3>
  </div>

  <div v-if="store.selectedDeckLoading == LoadingState.Success && currentPoll && !deckIsEmpty">
    <div class="uk-margin-bottom">
      <p class="uk-text-muted uk-text-small">Готово: <span>{{ percent }}%</span></p>
      <progress class="uk-progress uk-margin-remove-bottom " :value="(answered)"
                :max="(store.selectedDeckTotal)"></progress>
    </div>

    <div v-for="question in currentPoll.questions">

      <h3 class="uk-h3 uk-text-bold mg-text">{{ question.text }}</h3>

      <div class="uk-text-muted question-description" v-html="question.description"></div>
      <div>
        <div v-if="question.type === 'cards-radio'" class="question-block ">
          <CardRadio :question="question" :disabled="pollAnswered" @change="setAnswer"></CardRadio>
        </div>
      </div>

      <div class="uk-flex uk-flex-column uk-text-left question-block"
           v-if="question.type === 'radio'">
        <Radio :question="question" :disabled="pollAnswered" @change="setAnswer"></Radio>
      </div>

      <div class="uk-flex uk-flex-column uk-text-left question-block"
           v-if="question.type === 'checkbox'">
        <Checkbox :question="question" :disabled="pollAnswered" @change="setAnswer"></Checkbox>
      </div>

    </div>
  </div>

  <div v-if="pollAnswered && !deckIsEmpty">
    <div class="uk-margin-bottom">
      <h3>Бесконечные знания</h3>
      <p>
        Ответ на вопрос можно найти в документации. Изучить материал можно <a class="uk-link-text uk-text-danger" :href="currentPoll.article.url" target="_blank">по ссылке </a>
      </p>
    </div>

    <div class="uk-flex uk-flex-left">
      <button
          class="uk-button-danger uk-button" @click="next">
        Далее
      </button>
    </div>

  </div>

  <div v-if="deckIsEmpty">
    <div class="uk-margin-bottom">
      <div class="uk-text-center">
        <p class="uk-h1 mg-text">Колода пуста</p>
        <p>Вы отметили на все вопросы в колоде</p>
        <button
            class="uk-button-danger uk-button" @click="toDecks">
          Выбрать другую колоду
        </button>
      </div>

    </div>


  </div>

  <div v-if="!pollAnswered && currentPoll">
    <div class="uk-flex uk-flex-between">
      <div>
        <button
            class="uk-button-danger uk-button uk-margin-right " @click="answer">
          Ответить
        </button>
      </div>
      <div>
        <button
            v-if="hasAnyPolls"
            class="uk-button-default uk-button" @click="next">
          Пропустить
        </button>
      </div>
    </div>
  </div>

</template>

<script setup lang="ts">
import {computed, defineProps, ref} from 'vue';
import {userDecksStore} from "@/store/deck";
import {useRouter} from "vue-router";
import {LoadingState} from "@/models/api/v1/LoadingState";
import Loading from "@/components/Loading.vue";
import CardRadio from "@/components/poll/CardRadio.vue";
import Checkbox from "@/components/poll/Checkbox.vue";
import Radio from "@/components/poll/Radio.vue";
import {Api} from "@/types/api/v1/poll";
import QuestionAnswer from "@/models/api/v1/QuestionAnswer";
import Quiz = Api.V1.Quiz;
import Question = Api.V1.Question;
import { useNotification } from "@kyvg/vue3-notification";

type DeckProps = {
  deckId: number
}

const router = useRouter()
const store = userDecksStore();
const notifications = useNotification();

const props = defineProps<DeckProps>();

const currentPoll = ref<Quiz>();
const deckIsEmpty = ref(false);
const pollAnswered = ref(false);
const answers = ref<QuestionAnswer[]>([]);

const answered = ref(0);
const currentIndex = ref(0);
const hasAnyPolls = computed(() => store.selectedDeck.polls.length > 1);
const percent = computed(() => ((answered.value / store.selectedDeckTotal) * 100).toFixed(0))

store.loadDeck(props.deckId).then(() => {
  setNextPoll();
})

const setNextPoll = () => {

  let total = store.selectedDeck.polls.length - 1;
  let nextIndex = currentIndex.value + 1;

  if(total < 0){
    deckIsEmpty.value = true;
  }

  if (nextIndex > total) {
    nextIndex = 0;
  }

  // Считаем сколько всего разрешенных опросов
  const length = store.selectedDeck.polls.length - 1;
  // Если не осталось - колода пуста
  if (length < 0) {
    deckIsEmpty.value = true;
  }

  window.document.title = store.selectedDeck.deck.deck_name_result;


  currentPoll.value = store.selectedDeck.polls[nextIndex];
  currentIndex.value = nextIndex;
}


const answer = () => {
  const questions = currentPoll.value?.questions.map((question: Question) => question.id);

  questions.map((id: number) => {
    store.removeFromDeck(id);
  });

  pollAnswered.value = true;
  answered.value++;

  if (answers.value.length > 0) {
    store.sendAnswer(currentPoll.value.id, answers.value).catch((data) =>{
      notifications.notify({
        title: 'Ошибка',
        type: 'error',
        text: 'Не удалось сохранить ответ, повторите попытку позже или обратитесь к руководителю'
      });

    })
  }

}

const next = () => {
  pollAnswered.value = false;
  answers.value = [];
  setNextPoll();

}

const setAnswer = (answer: QuestionAnswer) => {
  // Ищем индекс существующего answer с таким же question
  const index = answers.value.findIndex((a) => a.question === answer.question);
  const answered = index !== -1; // Проверяем, существует ли answer с таким question

  const hasSelectedVariants = answer.variants.length > 0; // Проверяем, есть ли выбранные варианты

  if (answered && !hasSelectedVariants) {
    // Если answer существует, но нет выбранных вариантов, удаляем его из массива
    answers.value.splice(index, 1);
  } else if (answered) {
    // Если answer с таким question уже существует, заменяем его
    answers.value[index] = answer;
  } else {
    // Если answer с таким question не существует, добавляем его в массив
    answers.value.push(answer);
  }
};

const toDecks = () => {
  router.push({
    name: 'home',
  })
}
</script>

<style scoped lang="scss">

.mg-text {
  font-family: "BebasNeue";
  color: #274562;
}

.background-red {
  background-color: red;
}

.uk-h1 {
  font-weight: bold;
}


.uk-progress::-webkit-progress-value {
  background-color: red;
}

.uk-progress::-moz-progress-bar {
  background-color: red;
}

.uk-progress::-ms-fill {
  background-color: red;
}
</style>