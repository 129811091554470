import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "uk-container" }

import {useRoute} from 'vue-router'
import DeckView from "@/components/deck/DeckView.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'Deck',
  setup(__props) {

const route = useRoute();
const deckId = route.params.id;


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(DeckView, {
      "deck-id": Number(_unref(deckId))
    }, null, 8, ["deck-id"])
  ]))
}
}

})