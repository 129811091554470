import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import {defineComponent, ref} from 'vue';
import Header from '@/components/Header.vue';
import {useRoute, useRouter} from 'vue-router';
import Loading from "@/components/Loading.vue";
import PollsFetcher from "@/services/fetch/PollsFetcher";



export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const routeMatched = ref(false);

const route = useRoute();
const router = useRouter();

window.document.title = 'Опросы';

// Поддержка старых ссылок /?id={pollId} - редиректим на страницу опроса
router.afterEach((route) => {

  if (route.name == 'home' && route.query?.id) {
    const id = route.query?.id;
    const params = {...route.query};

    delete params['id'];
    router.push({name: 'poll', params: {id: id}, query: params});
  }

  // Оставляем поддержку перехода по внешнему ID
  if (route.name == 'home' && route.query?.['id-external']) {
    const id = route.query?.['id-external'];
    const params = {...route.query};

    delete params['id-external'];

    const fetcher = new PollsFetcher();

    fetcher.getPollByExternal(id).then(response => {
      router.push({name: 'poll', params: {id: response.data.poll.id}, query: params});

    }).catch((response) => {
      router.push({name: '404'})
    });


  }
  routeMatched.value = true;

})


defineComponent({
  components: {
    Header
  },

});

return (_ctx: any,_cache: any) => {
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_notifications = _resolveComponent("notifications")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(Header),
    (routeMatched.value)
      ? (_openBlock(), _createBlock(_component_RouterView, { key: 0 }))
      : _createCommentVNode("", true),
    (!routeMatched.value)
      ? (_openBlock(), _createBlock(Loading, { key: 1 }))
      : _createCommentVNode("", true),
    _createVNode(_KeepAlive),
    _createVNode(_component_notifications, { position: "top center" })
  ], 64))
}
}

})