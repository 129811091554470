<template>
  <div>
    <div class="uk-container">
      <div class="uk-card uk-card-default">
        <div class="uk-grid">
          <div class="uk-width-1-2@m">
            <img :src="getImage" class="uk-width-1-1" alt="">
          </div>
          <SendForm></SendForm>
        </div>
      </div>

    </div>
  </div>
</template>

<script lang="ts">
import SendForm from '@/components/SendForm.vue';
import {defineComponent} from "vue";
import {mapGetters} from "vuex";

export default defineComponent({
  name: 'Send',
  components: {
    SendForm,
  },
  computed: {
    ...mapGetters({
      quiz: 'quiz',
    }),
    getImage(): string {
      return this.quiz.images.finish ? this.quiz.images.start : '/img/finish.jpg'
    },
  },
})
</script>
