import {AxiosInstance, AxiosResponse} from "axios";
import ApiClient from "@/services/client/ApiClient";
import {Api} from "@/types/api/v1/poll";
import FetchArgs = Api.V1.FetchArgs;

export default class PollsFetcher {
    private _client: AxiosInstance;

    constructor() {
        this._client = ApiClient.init()
    }

    public get(pollId: number, secure: boolean): Promise<AxiosResponse> {
        if (secure) {
            return this._client.get(`/poll/view-secure/${pollId}`, {withCredentials: true});
        }
        return this._client.get(`/poll/view/${pollId}`, {withCredentials: true});

    }

    public getPollByExternal(id:string) : Promise<AxiosResponse> {
        return this._client.get(`/poll/find-by-external-id?id=${id}`, {withCredentials: true});
    }


    public fetchPoll(args: FetchArgs): Promise<AxiosResponse> {
        return this._client.post(`/poll/view`, args, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json'
            }
        });

    }

    public send(pollId: number, data: Api.V1.SendForm): Promise<AxiosResponse> {
        let link = `/poll/answer/?id=${pollId}`;
        let token = localStorage.getItem('access-token');

        if (token !== null) {
            link += `&access-token=${token}`;
        }
        return this._client.post(link, data, {withCredentials: true});
    }

    public checkAuth() {

        let link = `/profile`;
        let token = localStorage.getItem('access-token');

        if (token !== null) {
            link += `?access-token=${token}`;
        }

        return this._client.get(link, {withCredentials: true})
    }

}