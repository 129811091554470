<template>
  <div class="uk-container">

    <div v-if="resultLoadingState === LoadingState.Loading || resultLoadingState === LoadingState.Waiting">
      <Loading></Loading>
    </div>
    <div v-else-if="resultLoadingState === LoadingState.Success">
      <div class="uk-card uk-card-default uk-margin-bottom">
        <div class="uk-card-header">
          <h3 class="uk-card-title">{{ result.poll_title }}</h3>
        </div>

        <div class="uk-card-body">
          <div class="uk-text-bold uk-text-primary">Респондент:</div>

          <table class="uk-table uk-table-small uk-margin-remove">
            <tbody>
            <tr>
              <td>ID</td>
              <td>{{ result.poll_user.user_id }}</td>
            </tr>
            <tr>
              <td>ФИО</td>
              <td>{{ result.poll_user.user_full_name }}</td>
            </tr>
            <tr>
              <td>Email</td>
              <td>{{ result.poll_user.user_email }}</td>
            </tr>
            <tr>
              <td>Телефон</td>
              <td>{{ result.poll_user.user_phone }}</td>
            </tr>
            <tr>
              <td>Дата ответа</td>
              <td>{{ result.poll_user.user_answered_at }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="uk-margin-bottom">
        <div class="uk-card uk-card-default">
          <div class="uk-card-body">
            <div v-html="result.poll_result_description"></div>
          </div>
          <div class="uk-card-footer">
            Для того что бы ответ считался правильным - нужно выбрать <b>все верные варианты</b>
          </div>
        </div>

      </div>
      <div class="uk-margin-bottom" v-for="question in result.poll_questions">
        <div class="uk-card uk-card-default">
          <div class="uk-card-header">
            <h3 class="uk-text-default">
              {{ question.question_title }}
            </h3>
          </div>

          <div class="uk-card-body">
            <ul class="uk-list hyphen uk-list-divider">
              <li class="variant" v-for="variant in question.question_variants">
                <div :class="this.getVariantColor(question,variant)">
                  <div v-if="isCheckbox(question)">
                    <input type="checkbox" class="uk-checkbox" disabled :checked="variant.variant_is_selected"/>
                    {{ variant.variant_title }}

                  </div>
                  <div v-else-if="isRadio(question)">
                    <input type="radio" class="uk-radio" disabled :checked="variant.variant_is_selected"/>
                    {{ variant.variant_title }}

                  </div>
                  <div v-else>
                    <div class="uk-text-danger">Ошибка отображения вариантов. Неподдерживаемый тип вопроса.</div>
                  </div>

                </div>
              </li>
            </ul>
          </div>
          <div class="uk-card-footer">
            <h3 class="uk-card-title">
              <div v-html="this.createVerdict(question)"></div>
            </h3>
          </div>
        </div>

      </div>
    </div>
    <div v-else>
      <div class="uk-text-center">
        <p class="uk-h1">Произошла ошибка</p>
        <p class="uk-h3">{{ errorMessage }}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, ref, watch} from 'vue';
import {mapActions, mapGetters} from "vuex";
import {useRoute} from "vue-router";
import Loading from "@/components/Loading.vue";
import {LoadingState} from "@/models/api/v1/LoadingState";
import {Api} from "@/types/api/v1/result";
import Variant = Api.V1.Testing.Variant;
import Question = Api.V1.Testing.Question;

export enum CorrectnessState {
  ID_DEFAULT = 'default',
  ID_SUCCESS = 'success',
  ID_DANGER = 'danger',
}


enum QuestionType {
  ID_CHECKBOX = 10,
  ID_RADIO = 20,
  ID_CARDS_RADIO = 30,
  ID_CARDS_CHECKBOX = 40,
}

export default defineComponent({
  name: 'Result',
  components: {Loading},
  setup() {
    const route = useRoute()
    return {
      // with params.id
      uuid: route.params.uuid,
    }
  },
  methods: {
    ...mapActions({
      catchError: 'catchError',
      loadResult: 'loadResult',
    }),

    getVariantColor(question: Question, variant: Variant): string {
      if (!variant.variant_is_selected) {
        return '';
      }
      switch (question.question_correctness_state_id) {
        case CorrectnessState.ID_DANGER:
          return 'variant-invalid uk-text-danger uk-text-bold'
        case CorrectnessState.ID_SUCCESS:
          return 'variant-valid uk-text-success uk-text-bold'
        default:
          return '';
      }
    },
    isCheckbox(question: Question) {
      return [QuestionType.ID_CHECKBOX, QuestionType.ID_CARDS_CHECKBOX].indexOf(Number(question.question_type_id)) !== -1;
    },

    isRadio(question: Question) {
      return [QuestionType.ID_RADIO, QuestionType.ID_CARDS_RADIO].indexOf(Number(question.question_type_id)) !== -1;
    },
    createVerdict(question: Question) {
      if (question.question_correctness_state_id == CorrectnessState.ID_SUCCESS) {
        return `<span class="uk-text-success success-message"><span uk-icon="icon: check; ratio:0"></span>${question.question_correctness_result_message}</span>`;
      }

      if (question.question_correctness_state_id == CorrectnessState.ID_DANGER) {
        return `<div class="uk-text-danger uk-text-large error-message"><span uk-icon="icon: ban; ratio:0"></span> ${question.question_correctness_result_message}</div>
                <div class="uk-text-danger uk-text-default">Для того что бы ответ считался правильным - нужно выбрать <b>все верные варианты</b></div>
`;
      }

      return '';

    },
  },


  computed: {
    ...mapGetters({
      authProcessed: 'authProcessed',
      isAuth: 'isAuth',
      errorMessage: 'errorMessage',
    }),
    LoadingState() {
      return LoadingState
    },
    ...mapGetters({
      resultLoadingState: 'resultLoadingState',
      resultPageTitle: 'resultPageTitle',
      result: 'result',

    }),

  },
  mounted() {
    window.document.title = this.resultPageTitle;
     this.loadResult(this.uuid);


    watch(
        () => this.resultPageTitle,
        () => {
          window.document.title = this.resultPageTitle;
        });

  },


})
</script>

<style lang="scss" scoped>
.uk-h1, .uk-h3 {
  font-family: "BebasNeue";
  font-weight: bold;
  color: #274562;
}

.variant-invalid {
  .uk-checkbox {
    background-color: #f0506e !important;
  }

  .uk-radio {
    background-color: #f0506e !important;
    border-color: #f0506e !important;
  }
}

.variant-valid {
  .uk-checkbox {
    background-color: #32d296 !important;
  }

  .uk-radio {
    background-color: #32d296 !important;
    border-color: #32d296 !important;
  }
}


.uk-card:has(.error-message) {
  border-left: 3px solid #f0506e;
}

.uk-card:has(.success-message) {
  border-left: 3px solid #32d296;
}

.variant {
  .uk-checkbox {
    &:disabled {
      &:checked {
        background-image: url('@/assets/img/form-checkbox-white.svg');
        border-color: transparent;
      }
    }
  }

  .uk-radio {
    &:disabled {
      &:checked {
        background-image: url('@/assets/img/form-radio-white.svg');
        border-color: transparent;
      }
    }
  }
}
</style>