import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, vModelText as _vModelText, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["id", "value", "data-type", "onChange"]
const _hoisted_3 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.question['variants'], (variant) => {
      return (_openBlock(), _createElementBlock("label", {
        class: "form-label",
        for: variant.id
      }, [
        _withDirectives(_createElementVNode("input", {
          type: "radio",
          name: "variant",
          id: variant.id,
          value: variant.id,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedVariant) = $event)),
          "data-type": variant.type,
          onChange: ($event: any) => (_ctx.onChange($event, variant.type))
        }, null, 40, _hoisted_2), [
          [_vModelRadio, _ctx.selectedVariant]
        ]),
        _createTextVNode(" " + _toDisplayString(variant.title) + " ", 1),
        (variant.type === 'text' && Number(_ctx.selectedVariants) === variant.id)
          ? _withDirectives((_openBlock(), _createElementBlock("input", {
              key: 0,
              type: "text",
              id: variant.id,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedVariantValue) = $event)),
              placeholder: "Введите ответ",
              required: "required",
              class: "uk-input"
            }, null, 8, _hoisted_3)), [
              [_vModelText, _ctx.selectedVariantValue]
            ])
          : _createCommentVNode("", true)
      ], 8, _hoisted_1))
    }), 256)),
    _createElementVNode("div", null, _toDisplayString(_ctx.error), 1)
  ]))
}