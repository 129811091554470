import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, _cache[0] || (_cache[0] = [
    _createElementVNode("div", { class: "uk-container uk-margin-bottom" }, [
      _createElementVNode("a", { href: "https://cabinet.moigektar.ru/polls" }, [
        _createElementVNode("img", {
          src: "/img/logo.svg",
          alt: ""
        })
      ])
    ], -1)
  ])))
}