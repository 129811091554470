<template>
  <div class="uk-position-center">
    <vue-loaders-ball-clip-rotate-multiple color="#f0506e" scale="3"></vue-loaders-ball-clip-rotate-multiple>
  </div>


</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'Loading',
})
</script>