<template>
  <div class="uk-grid uk-child-width-1-3@m  uk-child-width-1-1  ">
    <div v-for="(variant) in question?.variants" class="uk-margin-bottom">
      <div class="uk-card uk-height-1-1 ">
        <label :for="variant.id">
          <input type="radio" name="card"
                 :id="variant.id"
                 :value="variant.id"
                 v-model="selected.id"
                 class="card-input-element"
                 :disabled="props.disabled"

          />

          <div class=" card-input uk-height-1-1">
            <div class="uk-background-cover  uk-panel uk-flex uk-flex-center uk-flex-middle"
                 v-if="variant.img"
                 style="height: 220px" :style="{ backgroundImage: `url(${variant.img})` }">
            </div>
            <div class="uk-background-cover  uk-panel uk-flex uk-flex-center uk-flex-middle" v-else
                 style="height: 220px;background-image: url(/img/no-img.jpeg);">
            </div>
            <div class="uk-padding-small">
              {{ variant.title }}
            </div>
          </div>
        </label>
      </div>
    </div>

  </div>
  <div>
<!--    {{ error }}-->
  </div>
</template>

<script setup lang="ts">
import {defineComponent, defineEmits, defineProps, ref, watch} from "vue";
import {Api} from "@/types/api/v1/poll";
import QuestionAnswer from "@/models/api/v1/QuestionAnswer";
import Question = Api.V1.Question;
import VariantAnswer from "@/models/api/v1/VariantAnswer";

type Props = {
  question: Question
  disabled: boolean | undefined
}

defineComponent({
  name: "Radio",
})


const props = defineProps<Props>()
const emit = defineEmits(['change']);

const selected = ref<VariantAnswer>({
  id:null,
  value:null
})

watch(
    () => selected.value,
    (newVal)=>{
      const answer: QuestionAnswer = {
        question: props.question.id,
        variants: [newVal]
      }

      emit('change', answer)

    },
    {deep:true}
)

</script>

<style lang="scss" scoped>
.uk-card {
  border: 1px solid #D7E2EA;
  box-shadow: none;
  color: #274562;

  label {

    display: block;

  }

  .card-input-element {
    display: none;
  }

  .card-input:hover {
    cursor: pointer;
  }

  .card-input-element:checked + .card-input {
    box-shadow: 0 0 1px 1px red;
  }

}
</style>
