<template>
  <div class="uk-container">
    <div v-if="store.allDecksLoadingState == LoadingState.Loading">
      <Loading></Loading>
    </div>
    <div v-if="store.allDecksLoadingState == LoadingState.Success">

      <div v-for="deck in store.allDecks" :key="deck.deck_id"
           class="deck-card uk-card uk-card-default uk-card-hover uk-margin-bottom cursor-pointer"
           @click="toDeck(deck.deck_id)">

        <div class="uk-card-header">
          <div class="uk-flex uk-flex-between uk-flex-middle">
            <div>
              <span class="uk-card-title uk-text-danger">{{ deck.deck_name_result }}</span>
            </div>
            <div>
              <span class="uk-text-bold uk-text-danger deck-total">{{ deck.deck_questions_total }}</span>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div v-if="store.allDecksLoadingState == LoadingState.Failed">
      <h3 class="uk-h3 uk-text-bold uk-text-center mg-text"> {{ store.allDecksLoadingMessage }}</h3>
    </div>
  </div>
</template>
<script setup lang="ts">
import {useRouter} from 'vue-router'
import {userDecksStore} from "@/store/deck";
import {LoadingState} from "@/models/api/v1/LoadingState";
import Loading from "@/components/Loading.vue";
import { useNotification } from "@kyvg/vue3-notification";

const store = userDecksStore();
const router = useRouter()
const notifications = useNotification();

const toDeck = (deckId: number) => {
  router.push({
    name: 'deck',
    params: {id: deckId}
  })
}

window.document.title =  'Колоды вопросов';

store.loadAllDecks();

</script>

<style scoped lang="scss">
.cursor-pointer {
  &:hover {
    cursor: pointer;
  }
}

.deck-card {
  transition: all ease-in-out 0.1s;

  &:hover {
    background-color: #868686 !important;
    transition: all ease-in-out 0.1s;

    .uk-card-title {
      transition: all ease-in-out 0.1s;

      color: white !important;
    }
    .deck-total{
      color: white !important;
    }
  }

}

.mg-text {
  font-family: "BebasNeue";
  color: #274562;
}

</style>