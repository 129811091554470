import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "uk-container" }
const _hoisted_2 = { class: "uk-text-center" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[0] || (_cache[0] = _createElementVNode("p", { class: "uk-h1" }, "Произошла ошибка", -1)),
        _createElementVNode("p", {
          class: "error-msg",
          innerHTML: _ctx.message
        }, null, 8, _hoisted_3)
      ])
    ])
  ]))
}