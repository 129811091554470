import {createStore} from 'vuex'
import Quiz          from "@/store/quiz";
import Question      from "@/store/question";
import SendForm      from "@/store/sendform";
import Result      from "@/store/result";

export default createStore({
    modules: {
        Quiz,
        Question,
        SendForm,
        Result
    }
})
