import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["id", "value", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.question['variants'], (variant) => {
      return (_openBlock(), _createElementBlock("label", {
        class: "form-label",
        for: variant.id
      }, [
        _withDirectives(_createElementVNode("input", {
          type: "checkbox",
          name: "categories[]",
          id: variant.id,
          value: variant.id,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedVariantIds) = $event)),
          disabled: this.selectedLength === this.question.max_answers_amount && this.selectedVariants?.indexOf(variant.id) === -1
        }, null, 8, _hoisted_2), [
          [_vModelCheckbox, _ctx.selectedVariantIds]
        ]),
        _createTextVNode(" " + _toDisplayString(variant.title), 1)
      ], 8, _hoisted_1))
    }), 256)),
    _createElementVNode("div", null, _toDisplayString(_ctx.error), 1)
  ]))
}