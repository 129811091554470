import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "uk-container" }
const _hoisted_2 = { class: "uk-h1 uk-margin-remove-bottom" }
const _hoisted_3 = { class: "question" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "uk-h3" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "uk-flex uk-flex-between uk-flex-bottom uk-margin-large-bottom w-progress" }
const _hoisted_10 = { class: "uk-width-2-3@m uk-width-1-3" }
const _hoisted_11 = { class: "uk-text-muted uk-text-small" }
const _hoisted_12 = ["value", "max"]
const _hoisted_13 = { class: "btn-form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Questions = _resolveComponent("Questions")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.quiz.title), 1),
      _cache[6] || (_cache[6] = _createElementVNode("p", { class: "uk-text-muted uk-text-small uk-margin-remove-top uk-margin-bottom" }, "Сделано в «Мой Гектар»", -1)),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.index < _ctx.quiz.questions_count)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_ctx.quiz.questions[_ctx.index].img)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createElementVNode("img", {
                      src: _ctx.quiz.questions[_ctx.index].img,
                      class: "uk-width-1-1",
                      alt: ""
                    }, null, 8, _hoisted_6)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("h3", _hoisted_7, _toDisplayString(_ctx.quiz.questions[_ctx.index]['text']), 1),
              _createElementVNode("div", {
                class: "uk-text-muted question-description",
                innerHTML: _ctx.description
              }, null, 8, _hoisted_8),
              _createVNode(_component_Questions),
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("p", _hoisted_11, [
                      _cache[3] || (_cache[3] = _createTextVNode("Готово: ")),
                      _createElementVNode("span", null, _toDisplayString(_ctx.percent) + "%", 1)
                    ]),
                    _createElementVNode("progress", {
                      class: "uk-progress uk-margin-remove-bottom",
                      value: (_ctx.index),
                      max: (_ctx.quiz.questions_count)
                    }, null, 8, _hoisted_12)
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _withDirectives(_createElementVNode("button", {
                      class: "uk-button uk-button-default uk-margin-right",
                      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.previousQuestion && _ctx.previousQuestion(...args)))
                    }, _cache[4] || (_cache[4] = [
                      _createElementVNode("img", {
                        src: "/img/arrow-l.svg",
                        alt: ""
                      }, null, -1)
                    ]), 512), [
                      [_vShow,  _ctx.index > 0]
                    ]),
                    _withDirectives(_createElementVNode("button", {
                      class: "uk-button-danger uk-button",
                      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (this.nextQuestionCommon && this.nextQuestionCommon(...args)))
                    }, _cache[5] || (_cache[5] = [
                      _createTextVNode(" Далее "),
                      _createElementVNode("img", {
                        src: "/img/arrow-r.svg",
                        alt: ""
                      }, null, -1)
                    ]), 512), [
                      [_vShow,  _ctx.index < _ctx.quiz.questions_count-1]
                    ]),
                    _withDirectives(_createElementVNode("button", {
                      class: "uk-button-danger uk-button",
                      id: "next-question-btn",
                      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.nextQuestion && _ctx.nextQuestion(...args)))
                    }, " Готово ", 512), [
                      [_vShow, _ctx.index == _ctx.quiz.questions_count-1]
                    ])
                  ])
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}