<template>
  <DeckList/>
</template>
<style lang="scss" scoped>
p {
  color: #4F4F4F;
}

.uk-h1 {
  font-family: "BebasNeue";
  font-weight: bold;
  color: #274562;
}

</style>


<script lang="ts">
import {defineComponent} from 'vue';
import Loading from '@/components/Loading.vue';
import DeckList from '@/components/deck/DeckList.vue';
import {mapActions, mapGetters} from "vuex";

export default defineComponent({
  name: 'Home',
  components: {
    Loading,
    DeckList,
  },
  methods: {
    ...mapActions({
      startQuiz: 'startQuiz',
      saveQuiz: 'saveQuiz',
      showFailPage: 'showFailPage'
    }),
  },
  computed: {
    declination(): string {
      const text = ['вопрос', 'вопроса', 'вопросов']
      const cases = [2, 0, 1, 1, 1, 2]

      let index = (this.quiz.questions_count % 100 > 4 && this.quiz.questions_count % 100 < 20) ? 2 : cases[(this.quiz.questions_count % 10 < 5) ? this.quiz.questions_count % 10 : 5];

      return text[Number(index)]

    },
    getImage(): string {
      return this.quiz.images.start ? this.quiz.images.start : '/img/start.jpg'
    },
    ...mapGetters({
      quiz: 'quiz',
      quizIsReady: 'quizIsReady',
    }),

    isFinished: () => {
      return true;
    },
  },


});
</script>