<template>
  <div class="uk-container">
    <div class="uk-text-center">
      <p class="uk-h1">Страница не найдена</p>
      <!--      <p class="uk-h3"></p>-->
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
  name: 'NotFound',
  mounted() {
    window.document.title = 'Страница не найдена';
  }
})
</script>