import {AxiosInstance} from "axios";
import ApiClient from "@/services/client/ApiClient";
import QuestionAnswer from "@/models/api/v1/QuestionAnswer";

export class DeckFetcher {
    private _client: AxiosInstance;

    constructor() {
        this._client = ApiClient.init()
    }

    public fetchAll() {
        return this._client.get('/deck/list', {withCredentials: true})
    }

    public fetchDeck(deckId:number) {
        return this._client.get(`/deck/view/${deckId}`, {withCredentials: true})
    }

    public answerPoll(pollId: number, data: QuestionAnswer[]){
        let link = `/poll/answer/?id=${pollId}`;

        return this._client.post(link, {answers: data}, {withCredentials: true});
    }
}