<template>
  <div>
    <label
        class="form-label"
        :for="variant.id"
        v-for="(variant) in variants"
    >
      <input
          type="checkbox"
          name="categories[]"
          :id="variant.id"
          :value="variant.id"
          :disabled="disabled"
          v-model="variant.selected"
      >
      {{ variant.title }}

      <input
          type="text"
          :id="variant.id"
          placeholder="Введите ответ"
          class="uk-input"
          v-model="variant.text"
          v-if="variant.type === 'text' && variant.selected"
      >
    </label>
    <div>
      <!--      {{ error }}-->
    </div>
  </div>
</template>

<script setup lang="ts">
import {defineComponent, defineEmits, defineProps, ref, watch} from "vue";
import {Api} from "@/types/api/v1/poll";
import QuestionAnswer from "@/models/api/v1/QuestionAnswer";
import Question = Api.V1.Question;

type Props = {
  question: Question
  disabled: boolean | undefined
}

type Variant = {
  id: number;
  type: string;
  title: string;
  order: number;
  img: string;
  selected: boolean,
  text: string
}

const props = defineProps<Props>()
const emit = defineEmits(['change']);

defineComponent({
  name: "Checkbox",

})

const variants = ref([]);

const buildVariants = (question: Question) => {
  return question.variants.map((value: Variant) => {
    return {
      id: value.id,
      type: value.type,
      title: value.title,
      order: value.order,
      img: value.img,
      text: '',
      selected: false
    }
  })
}

variants.value = buildVariants(props.question)

watch(
    () => props.question,
    (newVal) => {
      variants.value = buildVariants(newVal)
    },
    {deep: true}
)

watch(
    () => variants.value,
    (newVal) => {
      const selected = newVal.filter((v: Variant) => v.selected);

      const variants = selected.map((value: Variant): VariantAnswer => {
        return {
          id: value.id,
          value: value.text
        }
      })

      const answer: QuestionAnswer = {
        question: props.question.id,
        variants: variants

      }
      emit('change', answer)
    },
    {deep: true}
)

</script>

<style scoped>
.form-label {
  padding: 15px;
  border: 1px solid #D7E2EA;
  color: #4F4F4F;
  font-size: 1.1rem;
  margin-bottom: 15px;
  display: block;
}
</style>
