<template>
  <div>
    <label
        class="form-label"
        :for="variant.id"
        v-for="(variant) in question['variants']"
    >
      <input
          type="radio"
          name="variant"
          :id="variant.id"
          :value="variant.id"
          v-model="selectedVariant"
          :data-type="variant.type"
          @change="onChange($event, variant.type)"
      >
      {{ variant.title }}
      <input
          type="text"
          :id="variant.id"
          v-model="selectedVariantValue"
          placeholder="Введите ответ"
          required="required"
          class="uk-input"
          v-if="variant.type === 'text' && Number(selectedVariants) === variant.id"
      >
    </label>
    <div>
      {{ error }}
    </div>
  </div>
</template>

<script lang="ts">
import {mapActions, mapGetters} from "vuex";
import {defineComponent}        from "vue";

export default defineComponent({
  name    : "Radio",
  computed: {
    ...mapGetters({
      question        : 'currentQuestion',
      error           : 'errorMessage',
      selectedVariants: 'selectedVariants',
      variantValue    : 'variantValue',

    }),
    selectedVariant: {
      get: function (): number | null {

        if (this.selectedVariants?.length > 0) {
          return this.selectedVariants[0]
        }
        return null
      },
      set: function (value: number) {
        this.selectVariantsLength(1);
        this.selectVariants([value]);

        // setTimeout(() => {
        //   this.nextQuestion()
        // }, 400)
      }
    },
    selectedVariantValue: {
      get: function (): string {
        return this.variantValue;
      },
      set: function (value: string) {
        this.setVariantValue(value);
      }
    },
  },

  methods : {
    onChange(event:any, type:string){
      if(type !== 'text'){
        setTimeout(() => {
          this.nextQuestion()
        }, 400)
      }
    },
    ...mapActions({
      selectVariants: 'selectVariants',
      nextQuestion  : 'nextQuestion',
      setVariantValue: 'setVariantValue',
      selectVariantsLength: 'selectVariantsLength',
    }),
  },
})
</script>

<style scoped>
.form-label {
  padding: 15px;
  border: 1px solid #D7E2EA;
  color: #4F4F4F;
  font-size: 1.1rem;
  margin-bottom: 15px;
  display: block;
}
</style>
