<template>
  <div>
    <div class="uk-container">
      <div class="uk-h1 uk-margin-remove-bottom">{{ quiz.title }}</div>
      <p class="uk-text-muted uk-text-small uk-margin-remove-top uk-margin-bottom">Сделано в «Мой Гектар»</p>
      <div class="question ">
        <div v-if="index < quiz.questions_count">
          <div v-if="quiz.questions[index].img">
            <img :src="quiz.questions[index].img" class="uk-width-1-1" alt="">
          </div>

          <h3 class="uk-h3">{{ quiz.questions[index]['text'] }}</h3>
          <div class="uk-text-muted question-description" v-html="description"></div>
          <Questions></Questions>

          <div>
            <div class="uk-flex uk-flex-between uk-flex-bottom uk-margin-large-bottom w-progress">
              <div class="uk-width-2-3@m uk-width-1-3">
                <p class="uk-text-muted uk-text-small">Готово: <span>{{ percent }}%</span></p>
                <progress class="uk-progress uk-margin-remove-bottom" :value="(index)"
                          :max="(quiz.questions_count)"></progress>
              </div>
              <div class="btn-form">
                <button
                    class="uk-button uk-button-default uk-margin-right"
                    v-show=" index > 0"
                    @click="previousQuestion">
                  <img src="/img/arrow-l.svg" alt="">
                </button>
                <button
                    class="uk-button-danger uk-button"
                    v-show=" index < quiz.questions_count-1"
                    @click="this.nextQuestionCommon">
                  Далее <img src="/img/arrow-r.svg" alt="">
                </button>
                <button
                    class="uk-button-danger uk-button"
                    id="next-question-btn"
                    v-show="index == quiz.questions_count-1"
                    @click="nextQuestion">
                  Готово
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import {mapActions, mapGetters} from "vuex";
import Questions from "@/components/Questions.vue";
import MarkdownIt from 'markdown-it'

export default defineComponent({
  name: 'Quiz',
  components: {
    Questions
  },

  computed: {
    ...mapGetters({
      quiz: 'quiz',
      question: 'currentQuestion',
      index: 'quizCurrentQuestionIndex',
      selectedVariants: 'selectedVariants',
      selectedLength: 'selectedLength'
    }),
    percent() {
      //@ts-ignore
      return ((this.index) / (this.quiz.questions_count) * 100).toFixed(0);
    },
    description() {
      if (!this.question.description) {
        return null;
      }
      let bp = new MarkdownIt();
      let html = bp.render(this.question.description);

      let wrapper = document.createElement('div');
      wrapper.innerHTML = html;

      let links = wrapper.querySelectorAll('a');

      links.forEach((element, index) => {
          element.target = '_blank';
      })

      return wrapper.innerHTML;

    }
  },
  methods: {
    ...mapActions({
      showFailPage: 'showFailPage',
      nextQuestion: 'nextQuestion',
      previousQuestion: 'previousQuestion',
      selectVariantsLength: 'selectVariantsLength',

    }),
    nextQuestionCommon() {
      if (this.selectedLength < this.question.min_answers_amount) {
        this.$notify({
          title: 'Ошибка',
          type: 'error',
          text: `Минимальное кол-во выбранных вариантов ${this.question.min_answers_amount}`
        });
      }
      else {
        this.nextQuestion();
      }
    },
    selectVariants(variantId: number) {
      //@ts-ignore
      this.selectedVariants.push(variantId)
    }
  },

})
</script>
<style lang="scss">
.question-description {
  a {
    color: #ff0000c7 !important;
  }
}
</style>

<style lang="scss" scoped>
p {
  color: #4F4F4F;
}


.w-progress {

  @media (max-width: 600px) {
    background: #F2F6F8;
    padding: 3px;
  }

}

.uk-progress::-webkit-progress-value {
  background-color: red;
}

.uk-progress::-moz-progress-bar {
  background-color: red;
}

.uk-progress::-ms-fill {
  background-color: red;
}

.uk-progress {
  height: 4px;
}

.uk-text-muted {
  span {
    color: red;
  }
}


.uk-h1 {
  font-family: "BebasNeue";
  font-weight: bold;
  color: #274562;
  @media (max-width: 600px) {
    font-size: 1.8rem;
  }

}

.uk-h3 {
  color: #274562;
  @media (max-width: 600px) {
    font-size: 1.2rem;
  }

}


</style>
