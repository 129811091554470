import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "uk-container" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "uk-card-header" }
const _hoisted_6 = { class: "uk-flex uk-flex-between uk-flex-middle" }
const _hoisted_7 = { class: "uk-card-title uk-text-danger" }
const _hoisted_8 = { class: "uk-text-bold uk-text-danger deck-total" }
const _hoisted_9 = { key: 2 }
const _hoisted_10 = { class: "uk-h3 uk-text-bold uk-text-center mg-text" }

import {useRouter} from 'vue-router'
import {userDecksStore} from "@/store/deck";
import {LoadingState} from "@/models/api/v1/LoadingState";
import Loading from "@/components/Loading.vue";
import { useNotification } from "@kyvg/vue3-notification";


export default /*@__PURE__*/_defineComponent({
  __name: 'DeckList',
  setup(__props) {

const store = userDecksStore();
const router = useRouter()
const notifications = useNotification();

const toDeck = (deckId: number) => {
  router.push({
    name: 'deck',
    params: {id: deckId}
  })
}

window.document.title =  'Колоды вопросов';

store.loadAllDecks();


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(store).allDecksLoadingState == _unref(LoadingState).Loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(Loading)
        ]))
      : _createCommentVNode("", true),
    (_unref(store).allDecksLoadingState == _unref(LoadingState).Success)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(store).allDecks, (deck) => {
            return (_openBlock(), _createElementBlock("div", {
              key: deck.deck_id,
              class: "deck-card uk-card uk-card-default uk-card-hover uk-margin-bottom cursor-pointer",
              onClick: ($event: any) => (toDeck(deck.deck_id))
            }, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", null, [
                    _createElementVNode("span", _hoisted_7, _toDisplayString(deck.deck_name_result), 1)
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("span", _hoisted_8, _toDisplayString(deck.deck_questions_total), 1)
                  ])
                ])
              ])
            ], 8, _hoisted_4))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_unref(store).allDecksLoadingState == _unref(LoadingState).Failed)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("h3", _hoisted_10, _toDisplayString(_unref(store).allDecksLoadingMessage), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})