<template>
  <div>
    <div v-if="question.type === 'cards-radio'" class="question-block ">
      <CardRadio></CardRadio>
    </div>
  </div>

  <div class="uk-flex uk-flex-column uk-text-left question-block"
       v-if="question.type === 'radio'">
    <Radio></Radio>
  </div>
  <div class="uk-flex uk-flex-column uk-text-left question-block"
       v-if="question.type === 'checkbox'">
    <Checkbox></Checkbox>
  </div>
</template>

<script lang="ts">
import CardRadio         from '@/components/questions/CardRadio.vue'
import Checkbox          from '@/components/questions/Checkbox.vue'
import Radio             from '@/components/questions/Radio.vue'
import {mapGetters}      from "vuex";
import {defineComponent} from "vue";

export default defineComponent({
  name      : "Questions",
  components: {
    CardRadio,
    Checkbox,
    Radio
  },
  computed  : {
    ...mapGetters({
      question: 'currentQuestion'
    })
  }
})
</script>

<style scoped>
.question-block {
  min-height: 400px;
}


</style>
