import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "uk-container" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "uk-card uk-card-default uk-margin-bottom" }
const _hoisted_5 = { class: "uk-card-header" }
const _hoisted_6 = { class: "uk-card-title" }
const _hoisted_7 = { class: "uk-card-body" }
const _hoisted_8 = { class: "uk-table uk-table-small uk-margin-remove" }
const _hoisted_9 = { class: "uk-margin-bottom" }
const _hoisted_10 = { class: "uk-card uk-card-default" }
const _hoisted_11 = { class: "uk-card-body" }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = { class: "uk-margin-bottom" }
const _hoisted_14 = { class: "uk-card uk-card-default" }
const _hoisted_15 = { class: "uk-card-header" }
const _hoisted_16 = { class: "uk-text-default" }
const _hoisted_17 = { class: "uk-card-body" }
const _hoisted_18 = { class: "uk-list hyphen uk-list-divider" }
const _hoisted_19 = { class: "variant" }
const _hoisted_20 = { key: 0 }
const _hoisted_21 = ["checked"]
const _hoisted_22 = { key: 1 }
const _hoisted_23 = ["checked"]
const _hoisted_24 = { key: 2 }
const _hoisted_25 = { class: "uk-card-footer" }
const _hoisted_26 = { class: "uk-card-title" }
const _hoisted_27 = ["innerHTML"]
const _hoisted_28 = { key: 2 }
const _hoisted_29 = { class: "uk-text-center" }
const _hoisted_30 = { class: "uk-h3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.resultLoadingState === _ctx.LoadingState.Loading || _ctx.resultLoadingState === _ctx.LoadingState.Waiting)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_Loading)
        ]))
      : (_ctx.resultLoadingState === _ctx.LoadingState.Success)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.result.poll_title), 1)
              ]),
              _createElementVNode("div", _hoisted_7, [
                _cache[5] || (_cache[5] = _createElementVNode("div", { class: "uk-text-bold uk-text-primary" }, "Респондент:", -1)),
                _createElementVNode("table", _hoisted_8, [
                  _createElementVNode("tbody", null, [
                    _createElementVNode("tr", null, [
                      _cache[0] || (_cache[0] = _createElementVNode("td", null, "ID", -1)),
                      _createElementVNode("td", null, _toDisplayString(_ctx.result.poll_user.user_id), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _cache[1] || (_cache[1] = _createElementVNode("td", null, "ФИО", -1)),
                      _createElementVNode("td", null, _toDisplayString(_ctx.result.poll_user.user_full_name), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _cache[2] || (_cache[2] = _createElementVNode("td", null, "Email", -1)),
                      _createElementVNode("td", null, _toDisplayString(_ctx.result.poll_user.user_email), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _cache[3] || (_cache[3] = _createElementVNode("td", null, "Телефон", -1)),
                      _createElementVNode("td", null, _toDisplayString(_ctx.result.poll_user.user_phone), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _cache[4] || (_cache[4] = _createElementVNode("td", null, "Дата ответа", -1)),
                      _createElementVNode("td", null, _toDisplayString(_ctx.result.poll_user.user_answered_at), 1)
                    ])
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", {
                    innerHTML: _ctx.result.poll_result_description
                  }, null, 8, _hoisted_12)
                ]),
                _cache[6] || (_cache[6] = _createElementVNode("div", { class: "uk-card-footer" }, [
                  _createTextVNode(" Для того что бы ответ считался правильным - нужно выбрать "),
                  _createElementVNode("b", null, "все верные варианты")
                ], -1))
              ])
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.result.poll_questions, (question) => {
              return (_openBlock(), _createElementBlock("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("h3", _hoisted_16, _toDisplayString(question.question_title), 1)
                  ]),
                  _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("ul", _hoisted_18, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(question.question_variants, (variant) => {
                        return (_openBlock(), _createElementBlock("li", _hoisted_19, [
                          _createElementVNode("div", {
                            class: _normalizeClass(this.getVariantColor(question,variant))
                          }, [
                            (_ctx.isCheckbox(question))
                              ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                                  _createElementVNode("input", {
                                    type: "checkbox",
                                    class: "uk-checkbox",
                                    disabled: "",
                                    checked: variant.variant_is_selected
                                  }, null, 8, _hoisted_21),
                                  _createTextVNode(" " + _toDisplayString(variant.variant_title), 1)
                                ]))
                              : (_ctx.isRadio(question))
                                ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                                    _createElementVNode("input", {
                                      type: "radio",
                                      class: "uk-radio",
                                      disabled: "",
                                      checked: variant.variant_is_selected
                                    }, null, 8, _hoisted_23),
                                    _createTextVNode(" " + _toDisplayString(variant.variant_title), 1)
                                  ]))
                                : (_openBlock(), _createElementBlock("div", _hoisted_24, _cache[7] || (_cache[7] = [
                                    _createElementVNode("div", { class: "uk-text-danger" }, "Ошибка отображения вариантов. Неподдерживаемый тип вопроса.", -1)
                                  ])))
                          ], 2)
                        ]))
                      }), 256))
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_25, [
                    _createElementVNode("h3", _hoisted_26, [
                      _createElementVNode("div", {
                        innerHTML: this.createVerdict(question)
                      }, null, 8, _hoisted_27)
                    ])
                  ])
                ])
              ]))
            }), 256))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_28, [
            _createElementVNode("div", _hoisted_29, [
              _cache[8] || (_cache[8] = _createElementVNode("p", { class: "uk-h1" }, "Произошла ошибка", -1)),
              _createElementVNode("p", _hoisted_30, _toDisplayString(_ctx.errorMessage), 1)
            ])
          ]))
  ]))
}