import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "uk-width-1-2" }
const _hoisted_2 = { class: "uk-fieldset" }
const _hoisted_3 = { class: "uk-margin" }
const _hoisted_4 = { class: "uk-text-danger" }
const _hoisted_5 = { class: "uk-margin" }
const _hoisted_6 = { class: "uk-text-danger" }
const _hoisted_7 = { class: "uk-hidden" }
const _hoisted_8 = { class: "uk-margin" }
const _hoisted_9 = { class: "uk-text-danger" }
const _hoisted_10 = { class: "uk-margin" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_VueTelInput = _resolveComponent("VueTelInput")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Form, {
      "validation-schema": _ctx.schema,
      class: "uk-padding-large",
      onSubmit: _ctx.sendQuiz
    }, {
      default: _withCtx(({ errors }) => [
        _createElementVNode("fieldset", _hoisted_2, [
          _cache[5] || (_cache[5] = _createElementVNode("p", { class: "uk-h1 uk-text-center" }, [
            _createTextVNode("Введите данные "),
            _createElementVNode("br", { class: "uk-visible@m" }),
            _createTextVNode("для завершения опроса")
          ], -1)),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_Field, {
              class: "uk-input",
              modelValue: _ctx.username,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.username) = $event)),
              type: "text",
              placeholder: "Имя",
              name: "username"
            }, null, 8, ["modelValue"]),
            _createElementVNode("span", _hoisted_4, _toDisplayString(errors.username), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_Field, {
              class: "uk-input",
              modelValue: _ctx.email,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.email) = $event)),
              type: "text",
              placeholder: "E-mail",
              name: "email"
            }, null, 8, ["modelValue"]),
            _createElementVNode("span", _hoisted_6, _toDisplayString(errors.email), 1)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_Field, {
              class: "uk-input",
              modelValue: _ctx.phone,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.phone) = $event)),
              type: "text",
              name: "phone"
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_VueTelInput, {
              onInput: _ctx.updatePhone,
              modelValue: _ctx.phone,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.phone) = $event)),
              inputOptions: {placeholder:'Телефон', required:true},
              name: "phone"
            }, null, 8, ["onInput", "modelValue"]),
            _createElementVNode("span", _hoisted_9, _toDisplayString(errors.phone), 1)
          ]),
          _cache[6] || (_cache[6] = _createElementVNode("div", { class: "uk-margin" }, [
            _createElementVNode("button", {
              type: "submit",
              class: "uk-button uk-button-danger uk-width-1-1"
            }, "Отправить")
          ], -1)),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("button", {
              class: "uk-button uk-button-default uk-width-1-1",
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.resetQuiz && _ctx.resetQuiz(...args)))
            }, "Пройти снова")
          ])
        ])
      ]),
      _: 1
    }, 8, ["validation-schema", "onSubmit"])
  ]))
}