<template>
  <div class="uk-container">
    <div v-if="!quizIsReady">
      <Loading></Loading>
    </div>
    <div v-else>

      <img :src="getImage" class="uk-width-1-1" alt="">

      <p class="uk-h1">{{ this.getPollTypeName() }}: {{ quiz.title }}</p>
      <div v-html="quiz.content" v-if="quiz.content"></div>
      <!--      <p class="uk-margin-remove-top">Ответьте, пожалуйста, на {{ quiz.questions_count }} {{ declination }}</p>-->
      <a v-on:click="startQuiz" class="uk-button-danger uk-button">
        {{ this.getStartText() }}
      </a>
    </div>
  </div>

</template>

<style lang="scss" scoped>
p {
  color: #4F4F4F;
}

.uk-h1 {
  font-family: "BebasNeue";
  font-weight: bold;
  color: #274562;
}

</style>


<script lang="ts">
import {defineComponent} from 'vue';
import Loading from '@/components/Loading.vue';
import {mapActions, mapGetters} from "vuex";
import {ViewMode} from "@/services/fetch/Poll";
import {useRoute} from "vue-router";

export default defineComponent({
  name: 'PollStartPage',
  components: {
    Loading,
  },
  setup() {
    const route = useRoute()
    return {
      // with params.id
      id: route.params.id,
    }
  },
  methods: {
    ...mapActions({
      loadQuiz: 'loadQuiz',
      startQuiz: 'startQuiz',
      saveQuiz: 'saveQuiz',
      showFailPage: 'showFailPage',
    }),
    getStartText() {
      if (this.isTestable) {
        return 'Пройти тест';
      }

      return 'Пройти опрос';
    },
    getPollTypeName() {
      if (this.isTestable) {
        return 'Тест';
      }

      return 'Опрос';
    }
  },
  computed: {
    ...mapGetters({
      quiz: 'quiz',
      quizIsReady: 'quizIsReady',
      isTestable: 'isTestable',

    }),
    declination(): string {
      const text = ['вопрос', 'вопроса', 'вопросов']
      const cases = [2, 0, 1, 1, 1, 2]

      let index = (this.quiz.questions_count % 100 > 4 && this.quiz.questions_count % 100 < 20) ? 2 : cases[(this.quiz.questions_count % 10 < 5) ? this.quiz.questions_count % 10 : 5];

      return text[Number(index)]

    },
    getImage(): string {
      return this.quiz.images.start ? this.quiz.images.start : this.getDefaultStartImage;
    },

    getDefaultStartImage() : string{
      if(this.isTestable){
        return '/img/start_test.png';
      }
      return  '/img/start.jpg';
    },
    isFinished: () => {
      return true;
    },
  },
  mounted() {
    window.document.title = 'Опрос'
    let urlParams = new URLSearchParams(window.location.search)
    let token = urlParams.get('access-token');

    localStorage.removeItem('access-token');


    if (token) {
      localStorage.setItem('access-token', token);
    }

    let idExternal = urlParams.get('id-external');
    let viewMode = urlParams.get('secure') === '1' ? ViewMode.FORCE : ViewMode.DEFAULT;


    this.loadQuiz({
      poll_id: this.id,
      poll_external_id: idExternal,
      poll_view_mode: viewMode
    });
  }
});
</script>