import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Result from "@/views/Result.vue";
import Poll from "@/views/poll/Poll.vue";
import NotFound from "@/views/NotFound.vue";
import Home from "@/views/Home.vue";
import Deck from "@/views/Deck.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: 'Главная'
    }
  },
  {
    path:'/decks/:id',
    name: 'deck',
    component: Deck,
  },
  {
    path: '/poll/:id',
    name: 'poll',
    component: Poll,
    props: true
  },
  {
    path: '/result/:uuid',
    component: Result,
    props: true
  },
  {
    name: '404',
    path: '/404',
    component: NotFound
  },
  {
    path: '/:catchAll(.*)',
    component: NotFound
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
