<template>
  <div>
    <label
        class="form-label"
        :for="variant.id"
        v-for="(variant) in question.variants"
    >
      <input
          type="radio"
          name="variant"
          :id="variant.id"
          :value="variant.id"
          v-model="selected.id"
          :data-type="variant.type"
          :disabled="props.disabled"
      >
      {{ variant.title }}
      <input
          type="text"
          :id="variant.id"
          placeholder="Введите ответ"
          class="uk-input"
          v-model="selected.value"
          v-if="variant.type === 'text' && variant.selected"
      >
    </label>
    <div>
<!--      {{ error }}-->
    </div>
  </div>
</template>

<script setup lang="ts">
import {defineComponent, defineEmits, defineProps, ref, watch} from "vue";
import {Api} from "@/types/api/v1/poll";
import QuestionAnswer from "@/models/api/v1/QuestionAnswer";
import Question = Api.V1.Question;
import VariantAnswer from "@/models/api/v1/VariantAnswer";

type Props = {
  question: Question
  disabled: boolean | undefined
}

defineComponent({
  name: "Radio",
})


const props = defineProps<Props>()
const emit = defineEmits(['change']);

const selected = ref<VariantAnswer>({
  id:null,
  value:null
})

watch(
    () => selected.value,
    (newVal)=>{
      const answer: QuestionAnswer = {
        question: props.question.id,
        variants: [newVal]
      }

      emit('change', answer)

    },
    {deep:true}
)

</script>

<style scoped>
.form-label {
  padding: 15px;
  border: 1px solid #D7E2EA;
  color: #4F4F4F;
  font-size: 1.1rem;
  margin-bottom: 15px;
  display: block;
}
</style>
