<template>
  <div class="uk-grid uk-child-width-1-3@m  uk-child-width-1-1  ">
    <div v-for="(variant) in question['variants']" class="uk-margin-bottom">
      <div class="uk-card uk-height-1-1 ">
        <label :for="variant.id">
          <input type="radio" name="card"
                 :id="variant.id"
                 :value="variant.id"
                 v-model="selectedVariant"
                 class="card-input-element"/>

          <div class=" card-input uk-height-1-1">
            <div class="uk-background-cover  uk-panel uk-flex uk-flex-center uk-flex-middle"
                 v-if="variant.img"
                 style="height: 220px" :style="{ backgroundImage: `url(${variant.img})` }">
            </div>
            <div class="uk-background-cover  uk-panel uk-flex uk-flex-center uk-flex-middle" v-else
                 style="height: 220px;background-image: url(/img/no-img.jpeg);">
            </div>
            <div class="uk-padding-small">
              {{ variant.title }}
            </div>
          </div>
        </label>
      </div>
    </div>

  </div>
  <div>
    {{ error }}
  </div>
</template>

<script lang="ts">
import {mapActions, mapGetters} from "vuex";
import {computed, defineComponent} from "vue";

export default defineComponent({
  name    : "CardRadio",
  computed: {
    ...mapGetters({
      question        : 'currentQuestion',
      error           : 'errorMessage',
      selectedVariants: 'selectedVariants',
    }),
    selectedVariant: {
      get: function (): number | null {
        if (this.selectedVariants?.length > 0) {
          return this.selectedVariants[0]
        }
        return null
      },
      set: function (value: number) {
        this.selectVariants([value]);
        this.selectVariantsLength(1);

        setTimeout(() => {
          this.nextQuestion()
        }, 500)
      }
    }
  },
  methods: {
    ...mapActions({
      selectVariants: 'selectVariants',
      nextQuestion  : 'nextQuestion',
      selectVariantsLength: 'selectVariantsLength',
    }),
  },
})
</script>

<style lang="scss" scoped>
.uk-card {
  border: 1px solid #D7E2EA;
  box-shadow: none;
  color: #274562;

  label {

    display: block;

  }

  .card-input-element {
    display: none;
  }

  .card-input:hover {
    cursor: pointer;
  }

  .card-input-element:checked + .card-input {
    box-shadow: 0 0 1px 1px red;
  }

}
</style>
