import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "uk-container" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "uk-h1" }
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.quizIsReady)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_Loading)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("img", {
            src: _ctx.getImage,
            class: "uk-width-1-1",
            alt: ""
          }, null, 8, _hoisted_4),
          _createElementVNode("p", _hoisted_5, _toDisplayString(this.getPollTypeName()) + ": " + _toDisplayString(_ctx.quiz.title), 1),
          (_ctx.quiz.content)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                innerHTML: _ctx.quiz.content
              }, null, 8, _hoisted_6))
            : _createCommentVNode("", true),
          _createElementVNode("a", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.startQuiz && _ctx.startQuiz(...args))),
            class: "uk-button-danger uk-button"
          }, _toDisplayString(this.getStartText()), 1)
        ]))
  ]))
}