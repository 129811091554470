<template>
  <Header/>
  <RouterView v-if="routeMatched"/>
  <Loading v-if="!routeMatched"/>
  <keep-alive>
  </keep-alive>
  <notifications position="top center"/>
</template>

<script setup lang="ts">
import {defineComponent, ref} from 'vue';
import Header from '@/components/Header.vue';
import {useRoute, useRouter} from 'vue-router';
import Loading from "@/components/Loading.vue";
import PollsFetcher from "@/services/fetch/PollsFetcher";


const routeMatched = ref(false);

const route = useRoute();
const router = useRouter();

window.document.title = 'Опросы';

// Поддержка старых ссылок /?id={pollId} - редиректим на страницу опроса
router.afterEach((route) => {

  if (route.name == 'home' && route.query?.id) {
    const id = route.query?.id;
    const params = {...route.query};

    delete params['id'];
    router.push({name: 'poll', params: {id: id}, query: params});
  }

  // Оставляем поддержку перехода по внешнему ID
  if (route.name == 'home' && route.query?.['id-external']) {
    const id = route.query?.['id-external'];
    const params = {...route.query};

    delete params['id-external'];

    const fetcher = new PollsFetcher();

    fetcher.getPollByExternal(id).then(response => {
      router.push({name: 'poll', params: {id: response.data.poll.id}, query: params});

    }).catch((response) => {
      router.push({name: '404'})
    });


  }
  routeMatched.value = true;

})


defineComponent({
  components: {
    Header
  },

});
</script>

<style lang="scss">
@import 'assets/css/fonts.scss';


.uk-button-danger {
  background-color: red !important;
  text-transform: none !important;
  border-radius: 38px !important;

  &.outline {
    background-color: white !important;
    color: red !important;
    border-color: red !important;
  }
}

.uk-button-default {
  background: #FAFAFA !important;
  border: 1px solid #B4B4B4 !important;
  box-sizing: border-box !important;
  border-radius: 55px !important;
  padding: 0 14px !important;
  text-transform: none !important;
}

</style>
