import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "uk-grid uk-child-width-1-3@m uk-child-width-1-1" }
const _hoisted_2 = { class: "uk-margin-bottom" }
const _hoisted_3 = { class: "uk-card uk-height-1-1" }
const _hoisted_4 = ["for"]
const _hoisted_5 = ["id", "value"]
const _hoisted_6 = { class: "card-input uk-height-1-1" }
const _hoisted_7 = {
  key: 1,
  class: "uk-background-cover uk-panel uk-flex uk-flex-center uk-flex-middle",
  style: {"height":"220px","background-image":"url(/img/no-img.jpeg)"}
}
const _hoisted_8 = { class: "uk-padding-small" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.question['variants'], (variant) => {
        return (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("label", {
              for: variant.id
            }, [
              _withDirectives(_createElementVNode("input", {
                type: "radio",
                name: "card",
                id: variant.id,
                value: variant.id,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedVariant) = $event)),
                class: "card-input-element"
              }, null, 8, _hoisted_5), [
                [_vModelRadio, _ctx.selectedVariant]
              ]),
              _createElementVNode("div", _hoisted_6, [
                (variant.img)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: "uk-background-cover uk-panel uk-flex uk-flex-center uk-flex-middle",
                      style: _normalizeStyle([{"height":"220px"}, { backgroundImage: `url(${variant.img})` }])
                    }, null, 4))
                  : (_openBlock(), _createElementBlock("div", _hoisted_7)),
                _createElementVNode("div", _hoisted_8, _toDisplayString(variant.title), 1)
              ])
            ], 8, _hoisted_4)
          ])
        ]))
      }), 256))
    ]),
    _createElementVNode("div", null, _toDisplayString(_ctx.error), 1)
  ], 64))
}