import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, vModelText as _vModelText, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["id", "value", "data-type", "disabled"]
const _hoisted_3 = ["id"]

import {defineComponent, ref, watch} from "vue";
import {Api} from "@/types/api/v1/poll";
import QuestionAnswer from "@/models/api/v1/QuestionAnswer";
import VariantAnswer from "@/models/api/v1/VariantAnswer";

import Question = Api.V1.Question;
type Props = {
  question: Question
  disabled: boolean | undefined
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Radio',
  props: {
    question: {},
    disabled: { type: Boolean }
  },
  emits: ['change'],
  setup(__props: any, { emit: __emit }) {

defineComponent({
  name: "Radio",
})


const props = __props
const emit = __emit;

const selected = ref<VariantAnswer>({
  id:null,
  value:null
})

watch(
    () => selected.value,
    (newVal)=>{
      const answer: QuestionAnswer = {
        question: props.question.id,
        variants: [newVal]
      }

      emit('change', answer)

    },
    {deep:true}
)


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.question.variants, (variant) => {
      return (_openBlock(), _createElementBlock("label", {
        class: "form-label",
        for: variant.id
      }, [
        _withDirectives(_createElementVNode("input", {
          type: "radio",
          name: "variant",
          id: variant.id,
          value: variant.id,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selected.value.id) = $event)),
          "data-type": variant.type,
          disabled: props.disabled
        }, null, 8, _hoisted_2), [
          [_vModelRadio, selected.value.id]
        ]),
        _createTextVNode(" " + _toDisplayString(variant.title) + " ", 1),
        (variant.type === 'text' && variant.selected)
          ? _withDirectives((_openBlock(), _createElementBlock("input", {
              key: 0,
              type: "text",
              id: variant.id,
              placeholder: "Введите ответ",
              class: "uk-input",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((selected.value.value) = $event))
            }, null, 8, _hoisted_3)), [
              [_vModelText, selected.value.value]
            ])
          : _createCommentVNode("", true)
      ], 8, _hoisted_1))
    }), 256)),
    _cache[2] || (_cache[2] = _createElementVNode("div", null, null, -1))
  ]))
}
}

})