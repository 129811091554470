<template>
  <div class="uk-container">
    <DeckView :deck-id="Number(deckId)"/>
  </div>
</template>

<script setup lang="ts">
import {useRoute} from 'vue-router'
import DeckView from "@/components/deck/DeckView.vue";

const route = useRoute();
const deckId = route.params.id;

</script>

<style lang="scss">

</style>