<template>
  <div class="uk-width-1-2">
    <Form v-slot="{ errors }" :validation-schema="schema" class="uk-padding-large" @submit="sendQuiz">
      <fieldset class="uk-fieldset">
        <p class="uk-h1 uk-text-center">Введите данные <br class="uk-visible@m">для завершения опроса</p>
        <div class="uk-margin">
          <Field class="uk-input" v-model="username" type="text" placeholder="Имя" name="username"/>
          <span class="uk-text-danger">{{ errors.username }}</span>
        </div>
        <div class="uk-margin">
          <Field class="uk-input" v-model="email" type="text" placeholder="E-mail" name="email"/>
          <span class="uk-text-danger">{{ errors.email }}</span>
        </div>
        <div class="uk-hidden">
          <Field class="uk-input" v-model="phone" type="text" name="phone"/>
        </div>

        <div class="uk-margin">
          <VueTelInput @input="updatePhone" v-model="phone"
                       :inputOptions="{placeholder:'Телефон', required:true}" name="phone"></VueTelInput>
          <span class="uk-text-danger">{{ errors.phone }}</span>
        </div>
        <div class="uk-margin">
          <button type="submit" class="uk-button uk-button-danger uk-width-1-1">Отправить</button>
        </div>
        <div class="uk-margin">
          <button class="uk-button uk-button-default uk-width-1-1" @click="resetQuiz">Пройти снова</button>
        </div>
      </fieldset>
    </Form>
  </div>
</template>

<script lang="ts">
import {mapActions, mapGetters, mapMutations} from "vuex"
import {defineComponent} from "vue"
import {Field, Form} from 'vee-validate'
//@ts-ignore
import {VueTelInput} from 'vue3-tel-input'
import * as yup from 'yup';

export default defineComponent({
  name: "SendForm",
  components: {
    Field,
    Form,
    VueTelInput,
  },
  data() {
    return {
      phoneInput: {
        "countryCallingCode": "7",
        "nationalNumber": "",
        "number": "",
        "country": {
          "name": "",
          "iso2": "",
          "dialCode": ""
        },
        "countryCode": "",
        "valid": true,
        "formatted": "",
        "inputOptions.placeholder": 'test',
      },
      schema: yup.object({
        username: yup.string().min(2).required('Введите имя'),
        email: yup.string().required('Введите Email').email(),
        phone: yup.number().typeError('Введите номер телефона. Телефон может содержать только цифры')
      })
    }
  },
  computed: {
    ...mapGetters({
      sendFormUsername: 'sendFormUsername',
      sendFormEmail: 'sendFormEmail',
      sendFormPhone: 'sendFormPhone',
    }),
    username: {
      get(): string {
        return this.sendFormUsername
      },
      set(value: string): void {
        this.setSendFormUsername(value)
      },
    },
    email: {
      get(): string {
        return this.sendFormEmail
      },
      set(value: string): void {
        this.setSendFormEmail(value)
      },
    },
    phone: {
      get(): string {
        return this.sendFormPhone
      },
      set(value: string): void {
        this.setSendFormPhone(value)
      },
    },
  },
  methods: {
    ...mapActions({
      resetQuiz: 'resetQuiz',
      sendQuiz: 'sendQuiz',
    }),
    ...mapMutations({
      setSendFormUsername: 'setSendFormUsername',
      setSendFormEmail: 'setSendFormEmail',
      setSendFormPhone: 'setSendFormPhone',

    }),

    updatePhone(formatted: string, value: any) {
      console.log(this.schema)
      if (value) {
        let number = value?.number ?? ""
        this.setSendFormPhone(number)
        this.phone = number;
      }
    }
  },
})
</script>

<style lang="scss" scoped>
form {
  .uk-h1 {
    font-family: "BebasNeue";
    font-weight: bold;
    color: #274562;
    @media (max-width: 600px) {
      font-size: 1.8rem;
    }

  }
}
</style>
