import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, vModelText as _vModelText, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["id", "value", "disabled", "onUpdate:modelValue"]
const _hoisted_3 = ["id", "onUpdate:modelValue"]

import {defineComponent, ref, watch} from "vue";
import {Api} from "@/types/api/v1/poll";
import QuestionAnswer from "@/models/api/v1/QuestionAnswer";
import Question = Api.V1.Question;

type Props = {
  question: Question
  disabled: boolean | undefined
}

type Variant = {
  id: number;
  type: string;
  title: string;
  order: number;
  img: string;
  selected: boolean,
  text: string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Checkbox',
  props: {
    question: {},
    disabled: { type: Boolean }
  },
  emits: ['change'],
  setup(__props: any, { emit: __emit }) {

const props = __props
const emit = __emit;

defineComponent({
  name: "Checkbox",

})

const variants = ref([]);

const buildVariants = (question: Question) => {
  return question.variants.map((value: Variant) => {
    return {
      id: value.id,
      type: value.type,
      title: value.title,
      order: value.order,
      img: value.img,
      text: '',
      selected: false
    }
  })
}

variants.value = buildVariants(props.question)

watch(
    () => props.question,
    (newVal) => {
      variants.value = buildVariants(newVal)
    },
    {deep: true}
)

watch(
    () => variants.value,
    (newVal) => {
      const selected = newVal.filter((v: Variant) => v.selected);

      const variants = selected.map((value: Variant): VariantAnswer => {
        return {
          id: value.id,
          value: value.text
        }
      })

      const answer: QuestionAnswer = {
        question: props.question.id,
        variants: variants

      }
      emit('change', answer)
    },
    {deep: true}
)


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(variants.value, (variant) => {
      return (_openBlock(), _createElementBlock("label", {
        class: "form-label",
        for: variant.id
      }, [
        _withDirectives(_createElementVNode("input", {
          type: "checkbox",
          name: "categories[]",
          id: variant.id,
          value: variant.id,
          disabled: _ctx.disabled,
          "onUpdate:modelValue": ($event: any) => ((variant.selected) = $event)
        }, null, 8, _hoisted_2), [
          [_vModelCheckbox, variant.selected]
        ]),
        _createTextVNode(" " + _toDisplayString(variant.title) + " ", 1),
        (variant.type === 'text' && variant.selected)
          ? _withDirectives((_openBlock(), _createElementBlock("input", {
              key: 0,
              type: "text",
              id: variant.id,
              placeholder: "Введите ответ",
              class: "uk-input",
              "onUpdate:modelValue": ($event: any) => ((variant.text) = $event)
            }, null, 8, _hoisted_3)), [
              [_vModelText, variant.text]
            ])
          : _createCommentVNode("", true)
      ], 8, _hoisted_1))
    }), 256)),
    _cache[0] || (_cache[0] = _createElementVNode("div", null, null, -1))
  ]))
}
}

})