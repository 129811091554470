<template>
  <div class="uk-container">
    <component v-bind:is="currentView"></component>
  </div>
</template>

<style lang="scss" scoped>
p {
  color: #4F4F4F;
}

.uk-h1 {
  font-family: "BebasNeue";
  font-weight: bold;
  color: #274562;
}

</style>


<script lang="ts">
import {defineComponent} from 'vue';
import Loading from '@/components/Loading.vue';
import {mapGetters} from "vuex";

export default defineComponent({
  name: 'PollStartPage',
  components: {
    Loading,
  },
  computed: {
    ...mapGetters({
      quiz: 'quiz',
      quizIsReady: 'quizIsReady',
      currentView: 'currentView',
    }),
  },

});
</script>