<template>
  <div>
    <div class="uk-container">
      <div class="uk-text-center">
        <p class="uk-h1">Произошла ошибка</p>
        <p class="error-msg" v-html="message"></p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {mapGetters} from "vuex";

export default defineComponent({
  name: 'Fail',
  computed: {
    ...mapGetters({
      message: 'errorMessage',
    }),
  }
});
</script>

<style lang="scss" scoped>
.uk-h1 {
  font-family: "BebasNeue";
  font-weight: bold;
  color: #274562;
}

.error-msg{
  color: #274562;
  font-family: "BebasNeue";
}

.more-question {
  padding: 15px;
  border: 1px solid #D7E2EA;
  color: #4F4F4F;
  font-size: 1.1rem;
  margin-bottom: 15px;
}
</style>
