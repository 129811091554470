<template>
  <div>
    <div class="uk-container uk-margin-bottom">
      <a href="https://cabinet.moigektar.ru/polls">
        <img src="/img/logo.svg" alt="">
      </a>
    </div>
  </div>
</template>


<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Header',
  props: {
    msg: String,
  },
});
</script>
