<template>
  <div>
    <label
        class="form-label"
        :for="variant.id"
        v-for="(variant) in question['variants']"
    >
      <input
          type="checkbox"
          name="categories[]"
          :id="variant.id"
          :value="variant.id"
          v-model="selectedVariantIds"
          :disabled="this.selectedLength === this.question.max_answers_amount && this.selectedVariants?.indexOf(variant.id) === -1"
      >
      {{ variant.title }}

<!--            <input-->
<!--          type="text"-->
<!--          :id="variant.id"-->
<!--          v-model="selectedVariantValue"-->
<!--          placeholder="Введите ответ"-->
<!--          class="uk-input"-->
<!--          v-if="variant.type === 'text' && selectedVariantIds.indexOf(variant.id) > -1"-->
<!--      >-->
    </label>
    <div>
      {{ error }}
    </div>
  </div>
</template>

<script lang="ts">
import {mapActions, mapGetters} from "vuex";
import {defineComponent} from "vue";
import Vue from 'vue'


export default defineComponent({
  name: "Checkbox",
  computed: {
    ...mapGetters({
      question: 'currentQuestion',
      error: 'errorMessage',
      selectedVariants: 'selectedVariants',
      variantValue: 'variantValue',
      selectedLength: 'selectedLength'
    }),
    selectedVariantIds: {
      get: function (): Array<number> | null {
        if (this.selectedVariants?.length > 0) {
          return this.selectedVariants
        }
        return []
      },
      set: function (values: Array<number>) {

        this.selectVariantsLength(values?.length);

        if (this?.selectedLength > this.question.max_answers_amount) {
          this.$notify({
            title: 'Ошибка',
            type: 'error',
            text: `Максимально кол-во выбранных вариантов ${this.question.max_answers_amount}`
          });
        } else {
          this.selectVariants(values);
        }
      }
    },
    selectedVariantValue: {
      get: function (): string {
        return this.variantValue;
      },
      set: function (value: string) {
        this.setVariantValue(value);
      }
    },
  },
  methods: {
    ...mapActions({
      selectVariants: 'selectVariants',
      setVariantValue: 'setVariantValue',
      selectVariantsLength: 'selectVariantsLength',
      notification: 'notification'
    }),
  },
})
</script>

<style scoped>
.form-label {
  padding: 15px;
  border: 1px solid #D7E2EA;
  color: #4F4F4F;
  font-size: 1.1rem;
  margin-bottom: 15px;
  display: block;
}
</style>
