import {LoadingState} from "@/models/api/v1/LoadingState";
import {Decks} from "@/types/api/v1/deck";
import {Api} from "@/types/api/v1/poll";
import {DeckFetcher} from "@/services/fetch/DeckFetcher";
import {defineStore} from 'pinia'
import {ref} from 'vue'
import {AxiosResponse} from "axios";
import QuestionAnswer from "@/models/api/v1/QuestionAnswer";
import Deck = Decks.Api.V1.Deck;
import DeckItem = Decks.Api.V1.DeckItem;
import Quiz = Api.V1.Quiz;
import Question = Api.V1.Question;

export const userDecksStore = defineStore('decks', () => {
    const allDecksLoadingState = ref<LoadingState>(LoadingState.Loading);
    const allDecksLoadingMessage = ref<string>();
    const allDecks = ref<Deck[]>([]);

    const selectedDeck = ref<DeckItem>();
    const selectedDeckLoading = ref<LoadingState>(LoadingState.Loading);
    const selectedDeckTotal = ref(0);
    const selectedDeckLoadingMessage = ref<string>();

    const loadAllDecks = () => {
        allDecksLoadingState.value = LoadingState.Loading;

        let fetcher = new DeckFetcher();

        fetcher.fetchAll().then((response: AxiosResponse<Deck[]>) => {
            allDecks.value = response.data;
            allDecksLoadingState.value = LoadingState.Success;
        }).catch((e: any) => {
            allDecksLoadingState.value = LoadingState.Failed;

            if (e.response.status == 403) {
                allDecksLoadingMessage.value = 'Доступ запрещен. Проверьте авторизацию'
            } else {
                allDecksLoadingMessage.value = 'Произошла ошибка'

            }

        })
    }

    const loadDeck = async (deckId: number) => {
        selectedDeckLoading.value = LoadingState.Loading;

        return new Promise((resolve, reject) => {
            let fetcher = new DeckFetcher();

            fetcher.fetchDeck(deckId).then((response: AxiosResponse<DeckItem>) => {
                selectedDeck.value = response.data;
                selectedDeckLoading.value = LoadingState.Success;
                selectedDeckTotal.value = selectedDeck.value.polls.length;
                resolve(true);
            }).catch((e: any) => {
                selectedDeckLoading.value = LoadingState.Failed;

                if (e.response.status == 403) {
                    selectedDeckLoadingMessage.value = 'Доступ запрещен. Проверьте авторизацию'
                } else {
                    selectedDeckLoadingMessage.value = 'Произошла ошибка'

                }
                reject(false);
            })
        });

    }

    const removeFromDeck = (questionId: number) => {
        // Проверяем, что selectedDeck существует
        if (!selectedDeck.value) {
            return;
        }

        // Убедимся, что polls существует, иначе используем пустой массив
        const polls = selectedDeck.value.polls || [];

        // Фильтруем массив, оставляя только те элементы, у которых id не равен pollId
        const filteredPolls = polls.filter((poll: Quiz) => {
            let questions = poll.questions.filter((question: Question) => question.id == questionId);
            return questions.length == 0;
        });

        // Обновляем selectedDeck, заменяя polls на отфильтрованный массив
        selectedDeck.value.polls = filteredPolls;
    };


    const sendAnswer = async (pollId: number, answer: QuestionAnswer[]) => {
        let fetcher = new DeckFetcher();
       return fetcher.answerPoll(pollId, answer);
    }

    return {
        allDecksLoadingState,
        allDecks,
        selectedDeck,
        selectedDeckLoading,
        selectedDeckTotal,
        allDecksLoadingMessage,
        selectedDeckLoadingMessage,
        loadAllDecks,
        loadDeck,
        removeFromDeck,
        sendAnswer

    }
})
